import React, { Component } from 'react';
import strings from "../utils/localization";
import DatePicker, { registerLocale } from "react-datepicker";
import en from 'date-fns/locale/en-US';
import de from 'date-fns/locale/de';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import moment from "moment";
import Header from "../components/Header";
import Content from "../components/Content";
import Footer from "../components/Footer";

registerLocale("en", en);
registerLocale("de", de);
registerLocale("fr", fr);
registerLocale("it", it);

class GiveView extends Component {

    state = {
        location: null,
        time: null
    }

    handleChangeTime = (e) => {        
        if(moment(e).isValid()) {
            this.setState( {
                time: moment.parseZone(e).valueOf()
            })
        }
    }
    
    handleChangeLocation = (e) => {
        this.setState({
            location: e.target.value
        })
    }

    addVote = this.props.addVote;

    preventDefault = (e) => {
        e.preventDefault();
    }

    handleSubmit = () => {
        if (moment(this.state.time).isValid() && this.state.location !== "" && this.state.location != null && this.state.time !== "" && this.state.time != null) {
            this.addVote(this.state)
        } else {
            console.log("is not valid")
        }
    }
    
    render() { 
        const lang = navigator.language.slice(0,2)
        
        return (
            <div>
                <Header pageTitle={strings.headerTitleGive} pageDesc={strings.headerDescGive} />
                <Content>
                    <div className="content-right">
                        <form onSubmit={this.preventDefault} noValidate autoComplete="off">
                            <div className="form-group">
                                <label htmlFor="location">{strings.location}:</label>
                                <input type="text" id="location" name="location" onChange={this.handleChangeLocation} required />
                                <span className="form-group__helper">{strings.helperInputLocation}</span>
                            </div>
                            <div className="form-group">
                                <label htmlFor="time">{strings.time}:</label>
                                <DatePicker
                                    name="time"
                                    selected={this.state.time}
                                    showTimeSelect
                                    onChange={this.handleChangeTime}
                                    dateFormat="dd, MMMM yyyy H:mm"
                                    minDate={new Date(2020, 7, 31)}
                                    maxDate={new Date(2020, 8, 27)}
                                    timeIntervals={30}
                                    timeFormat="HH:mm"
                                    locale={lang}
                                    timeCaption={strings.time}
                                    minTime={moment().hour(6).valueOf()}
                                    maxTime={moment().hour(22).valueOf()}
                                    required
                                    autocomplete="off"
                                />
                                <span className="form-group__helper">{strings.helperInputTime}</span>
                            </div>
                        </form>
                    </div>
                </Content>
                <Footer>
                    <button onClick={this.handleSubmit} className="btn btn--primary" type="button">{strings.actionRegisterVote}</button>
                </Footer>
            </div>
         );
    }
}
 
export default GiveView;