import React, { Component } from "react";
import strings from "../utils/localization";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Content from "../components/Content";
import Modal from "../components/Modal";
import ImgGiver from "../img/Give.png";
import ImgRecipient from "../img/Receive.png";
import ImgGiverRetina from "../img/Give@2x.png";
import ImgRecipientRetina from "../img/Receive@2x.png";

class HomeView extends Component {

    state = {
        showModal: true
    }

    addDonor = this.props.registerAsDonor;
    addRecipient = this.props.registerAsRecipient;

    render () {

        return(
            <div>
                <Header pageTitle={strings.headerTitleHome} pageDesc={strings.headerDescHome} />
                <Content>
                        <div className="content-two">
                            <img className="content__image" src={ImgGiver} srcSet={`${ImgGiver} 1x, ${ImgGiverRetina} 2x`} alt=""/>
                            <p className="content__text">{strings.textSignUpGiver}</p>
                        </div>
                        <div className="content-two">
                            <img className="content__image" src={ImgRecipient} srcSet={`${ImgRecipient} 1x, ${ImgRecipientRetina} 2x`} alt=""/>
                            <p className="content__text">{strings.textSignUpRecipient}</p>
                        </div>
                </Content>
                
                <Footer classModifier={"footer--split"} >
                    <div className="footer__part">
                        <button className="btn btn--secondary" type="button" onClick={this.addDonor}>{strings.actionSignUpGiver}</button>
                    </div>
                    <div className="footer__part">
                        <button className="btn btn--secondary" type="button" onClick={this.addRecipient}>{strings.actionSignUpRecipient}</button>
                    </div>
                </Footer>
                <Modal isVisible={this.state.showModal}></Modal>
            </div>
        )
    }
}

export default HomeView;