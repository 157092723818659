import React, { Component } from "react";
import strings from "../utils/localization";

class Loader extends Component {
    
    state = {
        isVisible: this.props
    } 

    handleCloseModal =  () => {
        this.setState({
            isVisible: false
        })
    }

    render() { 

        if(!this.state.isVisible) {
            return null
        }

        return (
            <div className="modal-overlay">
                <div className="modal">
                    <div className="modal__body">  
                        <p className="modal__text">
                            {strings.helperBlockchainAwareness}
                        </p>
                    </div>
                    <div className="modal__footer">
                        <button onClick={this.handleCloseModal} className="btn btn--primary">{strings.actionCloseWarning}</button>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Loader;