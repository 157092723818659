import React, { Component } from 'react';
import "../App.scss";

import strings from "../utils/localization";

class Footer extends Component {

    render() { 
        return ( 
            <div className={"footer " + this.props.classModifier} >
                {this.props.children}
                <div className="footer__help">
                    <p>
                        {strings.helperContactHelp}
                        <a href="mailto:hello@votetandem.org">hello@votetandem.org</a>
                    </p>
                </div>
            </div> 
        );
    }
}
 
export default Footer;