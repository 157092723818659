import React, { Component } from 'react';
import VoteListItem from "../components/VoteListItem";
import strings from "../utils/localization";
import Header from "../components/Header";
import Content from "../components/Content";
import Footer from "../components/Footer";

class SelectView extends Component {

    claimVote = this.props.claimVote
    
    render() { 
        const { votes } = this.props.contractState;
        const activeVotes = votes.filter((vote) => (vote.status === "1" && JSON.parse(vote.data).time > Date.now()));
        const sortedActiveVotes = activeVotes.sort((a, b) => {
            return JSON.parse(a.data).time - JSON.parse(b.data).time;
        });
        
        const listElements = votes[0] ? (sortedActiveVotes.map(vote => {
            let data = JSON.parse(vote.data);
            return (
                <VoteListItem key={vote.id} location={data.location} time={data.time} reserveVote={() => this.claimVote(vote.id)} />
            )
        })) : (<p>{strings.emptyVoteList}</p>)
        
        return (
            <div>
                <Header pageTitle={strings.headerTitleSelect} pageDesc={strings.headerDescSelect} />
                <Content>
                    <div className="content-right">
                        {listElements}
                    </div>
                </Content>
                <Footer></Footer>
            </div>
         );
    }
}
 
export default SelectView;