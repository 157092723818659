import LocalizedStrings from 'react-localization';
let strings = new LocalizedStrings({
    en:{
      location: "Detailed description of location",
      time: "Time",
      dateAndTime: "Date and Time",

      actionReserveVote: "Reserve a Vote",
      actionRegisterVote: "Add a Vote",
      actionSignUpGiver: "Give a Vote",
      actionSignUpRecipient: "Receive a Vote",
      actionConfirmVote: "Confirm Vote Exchange",
      actionRejectVote: "Reject Vote Exchange",
      actionAddToCalendar: "Add to Calendar",

      actionCloseWarning: "Understood",

      textSignUpGiver: "Give away your ballot as a Swiss citizen.",
      textSignUpRecipient: "No voting right? Sign up and receive a ballot.",
      textPendingTitle: "Your meeting",
      textPending: "Come back regularly to check your vote's status.",
      textTitleGuide: "Guide",
      textTitleTalk: "Talk",
      textBulletGuide1: "Respect the other's opinion and privacy.",
      textBulletGuide2: "Be on time.",
      textBulletGuide3: "It's your decision to abandon the discussion when needed.",
      textBulletGuide4: "Only the giver is legally allowed to complete the ballot.",
      textBulletTalk1: "Decide if you complete the ballot together or just fill in the recipient's opinion",
      textBulletTalk2: "What is your personal reason for exchanging votes?",
      textBulletTalk3: "What impact do you hope to achieve from this meeting?",
      textBulletTalk4: "What is needed in order to form the opinion for voting?",
      textBulletTalk5: "What would you do differently in the next election?",
      textBracelet: "Fold and wear this bracelet in order to find each other at the meet-up location.",
      textBraceletStep1: "Cut a square from the short side of an A4 sheet.",
      textBraceletStep2: "Fold the bottom point to the top, leaving 1cm of space.",
      textBraceletStep3: "Flip to other side and make 1cm folds on long side, repeat until done. Insert both ends.",
      textTitleConfirmCompleted: "Vote Exchange is Confirmed and Completed",
      textTitleConfirmPrompt: "You Need to Confirm the Exchange",
      textTitleConfirmPending: "Your Partner Needs to Confirm the Exchange",
      textConfirmCompleted: "Congratulations! Thank you for exchanging votes and participating successfully.",
      textConfirmPrompt: "If you have completed the exchange, please confirm for completion.",
      textConfirmPending: "Your voting partner still needs to confirm the exchange for completion.",

      helperInputLocation: "Choose a location in public space.",
      helperInputTime: "You cannot select a meet-up during the night.",
      helperReminderBallot: "Don't forget to fill out the ballot!",
      helperTransactionPending: "Please wait for the transaction to process. This may take a moment.",
      helperMetamask: "In order to use votetandem.org install Metamask browser extension and select the following network: Ropsten Test Network. More infos on votetandem.org.",
      helperContactHelp: "Help: ",

      helperBlockchainAwareness: "Thank you for participating. Keep in mind that your registration is definite since nobody can delete entries from the system. Therefore, be mindful that somebody might be waiting for the vote exchange when you fix the meeting.",

      emptyVoteList: "No votes registered yet :'(",
      calenderEventTitle: "votetandem.org",
      calenderEventDescription: "You will meet up for the vote! (generated by votetandem.org)",

      headerTitleHome: "Home",
      headerTitleGuide: "Guide",
      headerTitleGive: "Give",
      headerTitlePending: "Pending",
      headerTitleSelect: "Select",
      headerTitleConfirm: "Confirm",

      headerDescHome: "Sign up as either a vote giver, providing the ballot or as a vote recipient, looking for a vote.",
      headerDescSelect: "Select from the list a vote with your preferred time and location. Remember that once the vote is selected, it cannot be reversed.",
      headerDescPending: "Thank you for your offered ballot. Vote recipients can now select your entry. This may take some time, check regularly for an update.",
      headerDescGuide: "Download the guide and event onto you phone for a digital calendar of you choice. The guide will help you with the conversation during the meeting.",
      headerDescGive: "Enter the location, date and time with your preferences. The location should consist of an easy to find address or landmark.",
      headerDescConfirm: "You are nearly done! Please confirm the exchange or click on Reject, if the meeting was not successful. You will be redirected to the start.",
    },
    de:{
      location: "Detailierte Ortsbeschreibung",
      time: "Zeit",
      dateAndTime: "Datum und Zeit",

      actionReserveVote: "Stimme reservieren",
      actionRegisterVote: "Stimme hinzufügen",
      actionSignUpGiver: "Stimme anbieten",
      actionSignUpRecipient: "Stimme erhalten",
      actionConfirmVote: "Stimmentausch bestätigen",
      actionRejectVote: "Stimmentausch ablehnen",
      actionAddToCalendar: "Zum Kalender hinzufügen",

      actionCloseWarning: "Verstanden",

      textSignUpGiver: "Gib deine Stimme als Schweizer Bürger her.",
      textSignUpRecipient: "Kein Stimmrecht? Registriere dich und erhalte einen Stimmzettel.",
      textPendingTitle: "Dein Treffen",
      textPending: "Schau regelmässig vorbei, um den Status deiner Stimme zu prüfen.",
      textTitleGuide: "Richtlinien",
      textTitleTalk: "Gespräch",
      textBulletGuide1: "Respektiere die Meinung und die Privatsphäre der anderen Person.",
      textBulletGuide2: "Sei pünktlich.",
      textBulletGuide3: "Es ist deine Entscheidung, die Diskussion bei Bedarf abzubrechen.",
      textBulletGuide4: "Nur die gebende Person ist gesetzlich berechtigt, den Stimmzettel auszufüllen.",
      textBulletTalk1: "Entscheide dich, ob ihr den Stimmzettel gemeinsam ausfüllt oder nur die Meinung des Empfängers ausfüllt.",
      textBulletTalk2: "Was ist dein persönlicher Grund für den Stimmentausch?",
      textBulletTalk3: "Welche Auswirkungen erhoffst du dir von diesem Treffen?",
      textBulletTalk4: "Was ist notwendig, um sich eine Meinung für die Abstimmung zu bilden?",
      textBulletTalk5: "Was würdest du bei der nächsten Wahl anders machen?",
      textBraceletStep1: "Schneide ein Quadrat aus ausgehend von der kurzen Seite eines A4-Blattes.",
      textBraceletStep2: "Falte die untere Ecke nach oben und lasse 1 cm Platz.",
      textBraceletStep3: "Auf die andere Seite wenden und 1 cm breite Falten auf der langen Seite machen, wiederholen bis es fertig ist. Beide Enden einsetzen.",
      textTitleConfirmCompleted: "Das Treffen ist bestätigt.",
      textTitleConfirmPrompt: "Du musst das Treffen betätigen.",
      textTitleConfirmPending: "Die andere Person muss das Treffen noch bestätigen.",
      textConfirmCompleted: "Herzlichen Glückwunsch! Vielen Dank für den Stimmentausch und die erfolgreiche Teilnahme.",
      textConfirmPrompt: "Wenn du den Stimmentausch abgeschlossen hast, bestätige bitte um abzuschliessen.",
      textConfirmPending: "Die andere Person muss den Stimmentausch noch bestätigen, um abzuschliessen.",
      helperContactHelp: "Hilfe: ",

      helperInputLocation: "Gib einen Standort im öffentlichen Raum an.",
      helperInputTime: "Du kannst keine Treffen in der Nacht auswählen.",
      helperReminderBallot: "Vergesst nicht, den Stimmzettel auszufüllen!",
      helperTransactionPending: "Bitte warte, bis die Transaktion abgeschlossen ist. Das kann einen Moment dauern.",
      helperMetamask: "Um votetandem.org zu verwenden, installiere die Metamask-Browsererweiterung und wähle das folgende Netzwerk aus: Ropsten Test Netzwerk. Mehr Infos auf votetandem.org.",
      helperBlockchainAwareness: "Vielen Dank für deine Teilnahme. Bitte beachte, dass deine Registrierung verbindlich ist, da niemand Einträge aus dem System löschen kann. Bedenke, dass jemand auf dich beim Stimmentausch warten könnte, wenn du das Treffen fixiert hast.",

      emptyVoteList: "Noch keine Stimme registriert :'(",
      calenderEventTitle: "votetandem.org",
      calenderEventDescription: "Du wirst dich dich für votetandem.org treffen! (generated by votetandem.org)",

      headerTitleHome: "Startseite",
      headerTitleGuide: "Leitfaden",
      headerTitleGive: "Spenden",
      headerTitlePending: "Warten",
      headerTitleSelect: "Auswählen",
      headerTitleConfirm: "Bestätigen",

      headerDescHome: "Melde dich entweder als stimmberechtigte Person an, die den Stimmzettel zur Verfügung stellt, oder als stimmsuchende Person an, die eine Stimme erhaltet.",
      headerDescSelect: "Wähle aus der Liste eine Stimme aus, mit dem bevorzugten Ort, Datum und der bevorzugten Zeit. Denke daran, dass die ausgewählte Stimme nicht zurück gegeben werden kann.",
      headerDescPending: "Vielen Dank für deine angebotene Stimme. Dein Eintrag kann nun ausgewählt werden. Es kann einige Zeit in Anspruch nehmen, überprüfe regelmässig auf ein Update.",
      headerDescGuide: "Lade den Guide und Termin auf direkt auf deinen Kalender herunter. Der Leitfaden wird dir während des Treffens beim Gespräch helfen.",
      headerDescGive: "Gib Standort, Datum und Uhrzeit mit deinen Vorgaben ein. Der Standort sollte aus einer leicht zu findenden Adresse oder einem Wahrzeichen bestehen.",
      headerDescConfirm: "Du bist fast fertig! Bitte bestätige den Tausch oder klicke auf Ablehnen, wenn das Meeting nicht erfolgreich war. Du wirst dann zum Start weitergeleitet.",
    },
    it: {
      location: "Descrizione dettagliata del luogo",
      time: "Ora",
      dateAndTime: "Giorno e ora",

      actionReserveVote: "Riservare Voto",
      actionRegisterVote: "Aggiungere Voto",
      actionSignUpGiver: "Diventa un datore",
      actionSignUpRecipient: "Ricevi un voto",
      actionConfirmVote: "Conferma scambio voto",
      actionRejectVote: "Rifiuta scambio voto",
      actionAddToCalendar: "Aggiungi al calendario",

      actionCloseWarning: "Ho capito",

      textSignUpGiver: "Dona il tuo voto come cittadino svizzero",
      textSignUpRecipient: "Non hai il diritto di voto? Registrati e ricevi una scheda elettorale.",
      textPendingTitle: "Il tuo incontro",
      textPending: "Torna regolarmente per controllare lo stato del tuo voto.",
      textTitleGuide: "Linee guida",
      textTitleTalk: "Discussione",
      textBulletGuide1: "Rispetta l'opinione e la privacy dell'altra persona",
      textBulletGuide2: "Sii puntuale.",
      textBulletGuide3: "E' la tua decisione di abbandonare la discussione se necessario.",
      textBulletGuide4: "Solo chi dona il voto è legalmente autorizzato a completare la scheda elettorale.",
      textBulletTalk1: "Compilate insieme la scheda elettorale o inserite semplicemente l'opinione del ricevente?",
      textBulletTalk2: "Qual è la vostra ragione personale per lo scambio di voti?",
      textBulletTalk3: "Quale impatto sperate di ottenere da questo incontro?",
      textBulletTalk4: "Che cosa è necessario per formare il parere per la votazione?",
      textBulletTalk5: "Cosa fareste diversamente nelle prossime elezioni?",
      textBraceletStep1: "Taglia un quadrato dal lato corto di un foglio A4.",
      textBraceletStep2: "Piega l'angolo inferiore verso l'alto, lasciando 1 cm di spazio.",
      textBraceletStep3: "Capovolgi il foglio e fai pieghe di 1 cm sul lato lungo, ripeti fino a alla fine. Inserisci entrambe le estremità.",
      textTitleConfirmCompleted: "Lo scambio di voti è stato confermato e completato",
      textTitleConfirmPrompt: "Devi confermare lo scambio",
      textTitleConfirmPending: "Il tuo partner non ha ancora confermato lo scambio",
      textConfirmCompleted: "Congratulazioni! Grazie per lo scambio di voti e per aver partecipato con successo.",
      textConfirmPrompt: "Se hai completato lo scambio, per favore conferma per terminare.",
      textConfirmPending: "Il tuo partner di voto deve ancora confermare lo scambio per terminare.",

      helperInputLocation: "Seleziona un luogo in spazi pubblici.",
      helperInputTime: "Non puoi selezionare incontri durante la notte.",
      helperReminderBallot: "Non dimenticare di compilare la scheda elettorale!",
      helperTransactionPending: "Attendi che la transazione venga eseguita. Può richiedere un momento.",
      helperMetamask: "Per utilizzare votetandem.org installa l'estensione del browser Metamask e seleziona la seguente rete: Ropsten Test Network. Maggiori informazioni su votetandem.org.",
      helperBlockchainAwareness: "Grazie per la partecipazione. Tieni presente che la tua registrazione è definitiva dato che nessuno può cancellare le iscrizioni dal sistema. Quindi, ricordati che qualcuno potrebbe stando in attesa dello scambio di voti quando fissi l'incontro.",
      helperContactHelp: "Aiuto: ",

      emptyVoteList: "Non ci sono ancora voti registrati :'(",
      calenderEventTitle: "votetandem.org",
      calenderEventDescription: "Ti incontrerai per votetandem.org! (generato da votetandem.org)",

      headerTitleHome: "Casa",
      headerTitleGuide: "Linee guida",
      headerTitleGive: "Donare",
      headerTitlePending: "In attesa",
      headerTitleSelect: "Selezionare",
      headerTitleConfirm: "Confermare",

      headerDescHome: "Iscriviti come donatore di voti, fornendo la scheda elettorale o come ricevente di voto, in cerca di una scheda elettorale.",
      headerDescSelect: "Seleziona dalla lista un voto con il luogo, da data e l'orario che preferisci. Ricorda che una volta che il voto è stato selezionato, non può più essere annullato.",
      headerDescPending: "Grazie per la scheda offerta. I riceventi possono ora selezionare il tuo voto. Questo potrebbe richiedere un po' di tempo, controlla regolarmente per un aggiornamento.",
      headerDescGuide: "Scarica la guida e l'evento sul tuo telefono per un calendario digitale a tua scelta. La guida ti aiuterà nella conversazione durante l'incontro.",
      headerDescGive: "Inserisci il luogo, la data e l'ora con le tue preferenze. Il luogo deve consistere di un indirizzo o punto di riferimento facile da trovare.",
      headerDescConfirm: "Hai quasi finito! Per favore conferma lo scambio o clicca su Rifiuta, se l'incontro non ha avuto successo. Sarai reindirizzato all'inizio.",
    },
    fr: {
      location: "Description détaillée du lieu",
      time: "Heure",
      dateAndTime: "Date et heure",

      actionReserveVote: "Réserver un vote",
      actionRegisterVote: "Ajouter un vote",
      actionSignUpGiver: "Donner un vote",
      actionSignUpRecipient: "Recevoir un vote",
      actionConfirmVote: "Confirmer l'échange de votes",
      actionRejectVote: "Refuser l'échange de votes",
      actionAddToCalendar: "Ajouter au calendrier",
      actionCloseWarning: "J'ai compris",

      textSignUpGiver: "Donne ton bulletin de vote en tant que citoyen suisse.",
      textSignUpRecipient: "Sans droit de vote? Inscris-toi et reçois un bulletin de vote.",
      textPendingTitle: "Ta rencontre",
      textPending: "Retourne sur cette page régulièrement pour vérifier l'état de ton vote.",
      textTitleGuide: "Guide",
      textTitleTalk: "Discussion",
      textBulletGuide1: "Respectes l'opinion et la vie privée de l'autre.",
      textBulletGuide2: "Sois à l'heure.",
      textBulletGuide3: "C'est à toi de décider d'abandonner la discussion si nécessaire.",
      textBulletGuide4: "Seul le donateur est légalement autorisé à remplir le bulletin de vote.",
      textBulletTalk1: "Décide si tu remplis le bulletin de vote ensemble ou si tu remplis simplement l'opinion du destinataire.?",
      textBulletTalk2: "Quelle est la raison personnelle pour laquelle vous échangez des votes?",
      textBulletTalk3: "Quel impact espérez-vous obtenir de cette rencontre?",
      textBulletTalk4: "Qu'est-ce qui est nécessaire pour former une opinion de vote?",
      textBulletTalk5: "Que ferais-tu différemment aux prochaines élections?",
      textBraceletStep1: "Découpe un carré commençant par le petit côté d'une feuille A4.",
      textBraceletStep2: "Plie la pointe du bas vers le haut, en laissant 1 cm d'espace.",
      textBraceletStep3: "Tourne de l'autre côté et fais des plis de 1 cm sur le côté long, répète jusqu'à ce que ce soit fait. Insérer les deux extrémités.",
      textTitleConfirmCompleted: "L'échange de votes est confirmé et complété",
      textTitleConfirmPrompt: "Tu dois confirmer l'échange",
      textTitleConfirmPending: "Votre partenaire doit encore confirmer l'échange",
      textConfirmCompleted: "Félicitations! Merci d'avoir échangé le vote et d'avoir participé avec succès.",
      textConfirmPrompt: "Si tu as terminé l'échange, merci de confirmer pour finaliser.",
      textConfirmPending: "Ton partenaire de vote doit encore confirmer l'échange pour qu'il soit complété.",

      helperInputLocation: "Choisis un lieu dans un espace public.",
      helperInputTime: "Tu ne peux pas choisir un rendez-vous pendant la nuit.",
      helperReminderBallot: "N'oublies pas de remplir le bulletin de vote!",
      helperTransactionPending: "Merci d'attendre que la transaction soit traitée. Cela peut prendre un moment.",
      helperMetamask: "Pour utiliser votetandem.org, installe l'extension de navigateur Metamask et sélectionne le réseau suivant: Ropsten Test Network. Plus d'infos sur votetandem.org.",
      helperBlockchainAwareness:"Merci de votre participation. Rappeles-toi que ton inscription est définitive puisque personne ne peut supprimer des entrées du système. Par conséquent, n'oublies pas que quelqu'un pourrait attendre l'échange de votes quand tu fixes le rendez-vous.",
      helperContactHelp: "Assitance: ",

      emptyVoteList: "Aucun vote n'a encore été enregistré :'(",
      calenderEventTitle: "votetandem.org",
      calenderEventDescription: "Tu as un rendez-vous pour le vote! (généré par votetandem.org)",

      headerTitleHome: "Page d'Accueil",
      headerTitleGuide: "Guide",
      headerTitleGive: "Donner",
      headerTitlePending: "En Attente",
      headerTitleSelect: "Choisir",
      headerTitleConfirm: "Confirmer",
      headerDescHome: "Inscris-toi en tant que votant, en fournissant le bulletin de vote ou en tant que destinataire du vote, à la recherche d'un bulletin de vote .",
      headerDescSelect: "Sélectionne dans la liste un vote avec le lieu, la date et l'heure de ton choix. N'oublie pas qu'une fois que le vote est sélectionné, il ne peut être annulé.",
      headerDescPending: "Merci pour ton bulletin de vote. Les destinataires peuvent maintenant sélectionner ton bulletin de vote. Cela peut prendre un peu de temps, vérifie régulièrement pour une actualisation.",
      headerDescGuide: "Télécharge le guide et l'événement sur le calendrier de ton téléphone. Le guide t'aidera dans la conversation pendant la réunion.",
      headerDescGive: "Sélectionne le lieu, la date et l'heure avec tes préférences. Le lieu doit être une adresse ou un point de repère facile à trouver.",
      headerDescConfirm: "Tu as presque fini! Merci de confirmer l'échange ou clique sur Refuser, si la réunion n'a pas réussi. Tu seras redirigé à l’accueil.",
    },
});

export default strings;
