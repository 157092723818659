import React, { Component } from 'react';
import strings from "../utils/localization";
import Header from "../components/Header";
import Content from "../components/Content";
import Footer from "../components/Footer";

class ConfirmView extends Component {

    confirmVote = this.props.confirmVote;
    rejectVote = this.props.rejectVote;
    
    render() { 
        const { userVote, isDonor, isRecipient } = this.props.contractState;
        let title = "Oh-oh…";
        let text = "Something went really wrong";
        let footerContent;

        if (isDonor) {
            if (userVote.confDonor) {
                if(userVote.confRecipient) {
                    title = strings.textTitleConfirmCompleted
                    text = strings.textConfirmCompleted
                    footerContent = (<Footer></Footer>)
                } else {
                    title = strings.textTitleConfirmPending
                    text = strings.textConfirmPending
                    footerContent = (<Footer></Footer>)
                }
            } else {
                title = strings.textTitleConfirmPrompt
                text = strings.textConfirmPrompt
                footerContent = (
                    <Footer classModifier={"footer--split"} >
                        <div className="footer__part">
                            <button className="btn" type="button" onClick={() => this.rejectVote(userVote)}>{strings.actionRejectVote}</button>
                        </div>
                        <div className="footer__part">
                            <button className="btn btn--primary" type="button" onClick={() => this.confirmVote(userVote)}>{strings.actionConfirmVote}</button>
                        </div>
                    </Footer>
                )
            }
        } else if (isRecipient) {
            if (userVote.confRecipient) {
                if(userVote.confDonor) {
                    title = strings.textTitleConfirmCompleted
                    text = strings.textConfirmCompleted
                    footerContent = (<Footer></Footer>)
                } else {
                    title = strings.textTitleConfirmPending
                    text = strings.textConfirmPending
                    footerContent = (<Footer></Footer>)
                }
            } else {
                title = strings.textTitleConfirmPrompt
                text = strings.textConfirmPrompt
                footerContent = (
                    <Footer classModifier={"footer--split"} >
                        <div className="footer__part">
                            <button className="btn" type="button" onClick={() => this.rejectVote(userVote)}>{strings.actionRejectVote}</button>
                        </div>
                        <div className="footer__part">
                            <button className="btn btn--primary" type="button" onClick={() => this.confirmVote(userVote)}>{strings.actionConfirmVote}</button>
                        </div>
                    </Footer>
                )
            }
        }

        return (
            <div>
                <Header pageTitle={strings.headerTitleConfirm} pageDesc={strings.headerDescConfirm} />
                <Content>
                    <div className="content-right">
                        <h2>{title}</h2>
                        <p>{text}</p>
                    </div>
                </Content>
                {footerContent}
                {/* <Footer classModifier={"footer--split"} >
                    <div className="footer__part">
                        <button className="btn" type="button" onClick={() => this.rejectVote(userVote)}>{strings.actionRejectVote}</button>
                    </div>
                    <div className="footer__part">
                        <button className="btn btn--primary" type="button" onClick={() => this.confirmVote(userVote)}>{strings.actionConfirmVote}</button>
                    </div>
                </Footer> */}

            </div>
         );
    }
}
 
export default ConfirmView;