import React, { Component } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import BallotTandemContract from "./contracts/BallotTandem.json";
import getWeb3 from "./utils/getWeb3";
import HomeView from "./views/home";
import GiveView from "./views/give";
import SelectView from "./views/select";
import PendingView from "./views/pending";
import GuideView from "./views/guide";
import ConfirmView from "./views/confirm";
import Loader from "./components/Loader";

import "./App.scss";

class App extends Component {
  state = {
    web3: null, 
    accounts: null, 
    contract: null,
    votes: [], actions: [],
    isDonor: false,
    isRecipient: false,
    voteTimeOver: false,
    userVote: {
      data: false,
      donor: false,
      status: false,
      recipient: false,
      confDonor: false,
      confRecipient: false
    },
    isProcessing: false
  };

  componentDidMount = async () => {
    try {
      // Get network provider and web3 ballot.
      const web3 = await getWeb3();
      
      // Use web3 to get the user's accounts.
      const accounts = await web3.eth.getAccounts();

      console.log('accounts', accounts);

      // Get the contract ballot.
      const networkId = await web3.eth.net.getId();

      if(!BallotTandemContract.networks[networkId]) throw new Error('contract not deployed on this network')
      const ballotAdress = BallotTandemContract.networks[networkId].address

      const deployedNetwork = BallotTandemContract.networks[networkId];
      const ballot = new web3.eth.Contract(
        BallotTandemContract.abi,
        deployedNetwork && deployedNetwork.address
      );

      console.log('initialized with ballot', ballotAdress)

      // Set web3, accounts, and contract to the state, and then proceed with an
      // example of interacting with the contract's methods.
      this.setState({ web3, accounts, contracts: { ballot } }, 
      this.installListeners);

      if(accounts[0] !== this.state.sender) {
        this.setState({ sender: accounts[0] })
      }
      
      this.checkDonor();
      this.checkRecipient();

    } catch (error) {
      // Catch any errors for any of the above operations.
      alert(
        `Please check the tutorial on the homepage and make sure to install the MetaMask browser extension. Also make sure to switch MetaMask to the Ropsten network. For help: hello@votetandem.org`
      );
      console.error(error);
    }

  };

  loadVote = async(web3, ballot, id) => {
    // Retrieve the fields of the offer
    const { data, donor, status, recipient, confDonor, confRecipient } = await ballot.methods.votes(id.toString()).call();
    const { sender } = this.state;

    // Update the state
    this.setState(({votes}) => ({
      votes: [...votes, {
        id: id.toString(),
        data: data.toString(),
        donor: web3.utils.toChecksumAddress(donor),
        recipient: web3.utils.toChecksumAddress(recipient),
        status,
        confDonor,
        confRecipient
      }]
    }))
    
    if ((donor || recipient) === sender) {
      this.setState({
        userVote: {
          id,
          data: data.toString(),
          donor: web3.utils.toChecksumAddress(donor),
          recipient: web3.utils.toChecksumAddress(recipient),
          status,
          confDonor,
          confRecipient
        },
        voteTimeOver: JSON.parse(data.toString()).time < Date.now()
      })
    }
  }

  handleVoteUpdated = async(web3, { blockNumber, transactionHash }, { id, data, status, donor, recipient, confDonor, confRecipient }) => {
    const { sender } = this.state;
    // Update the votes list
    this.setState(({votes}) => ({
      votes: votes.map((vote) => {
        if(vote.id === id.toString()) {
          return { ...vote,
            id,
            data: data.toString(),
            status: status.toString(),
            donor: web3.utils.toChecksumAddress(donor),
            recipient: web3.utils.toChecksumAddress(recipient),
            confDonor,
            confRecipient
          }
        } else return vote
      })
    }))

    if (recipient === sender) {
      this.setState({
        userVote: {
          id,
          data: data.toString(),
          donor: web3.utils.toChecksumAddress(donor),
          recipient: web3.utils.toChecksumAddress(recipient),
          status,
          confDonor,
          confRecipient
        },
        voteTimeOver: JSON.parse(data.toString()).time < Date.now()
      })
    }

    // Update the recent actions list
    let action

    switch (status.toString()) {
      case "0": action = 'UPDATED'
        break;
      case "1": action = 'TAKEN'
        break;
      case "2": action = 'CONFIRMED'
        break;
      case "3": action = 'REJECTED'
        break;
      default: action = 'UNKNOWN'
    }

    const entry = {
      timestamp: (await web3.eth.getBlock(blockNumber)).timestamp,
      transactionHash,
      id: id.toString(),
      action
    }

    this.setState(({actions}) => ({
      actions: [entry, ...actions]
    }))
  }

  installListeners = async() => {
    // Extract part of the state that never changes
    const { web3, contracts: { ballot } } = this.state

    ballot.events.VoteCreated({ fromBlock: 0 }, () => {})
    .on('data', (event) => this.loadVote(web3, ballot, event.returnValues.id))
    .on('changed', (event) => alert('Warning: reorg detected, please reload'))
    .on('error', console.error);

    const blockNumber = await web3.eth.getBlockNumber()

    // Get recent (within 40 blocks) and future OfferChanged events
    ballot.events.VoteUpdated({ fromBlock: Math.max(blockNumber - 40, 0) })
    .on('data', (event) => this.handleVoteUpdated(web3, event, event.returnValues))
    .on('changed', (event) => alert('Warning: reorg detected, please reload'))
    .on('error', console.error);

    // check wether the main account changes every 1s
    setInterval(async () => {
      let accounts = await web3.eth.getAccounts()

      if(accounts[0] !== this.state.sender) {
        this.setState({ sender: accounts[0] })
      }
    }, 1000)
  }

  // various helper functions for contract interaction
  checkDonor = async () => {
    const { contracts: { ballot }, sender } = this.state
    try {
      const response = await ballot.methods.isDonor(sender).call({ from: sender });
      // console.log('is donor?', response);
      if (response) {
        this.setState({ isDonor: true })
      }
    } catch (e) {
      console.error(e);    
    }
  }
  
  checkRecipient = async () => {
    const { contracts: { ballot }, sender } = this.state
    try {
      const response = await ballot.methods.isRecipient(sender).call({from: sender});
      // console.log('is recipient?', response);
      if (response) {
        this.setState({ isRecipient: true })
      }
    } catch (e) {
      console.error(e);    
    }
  }

  addDonor = async () => {
    this.setState({
      isProcessing: true
    })
    const { contracts: { ballot }, sender } = this.state;
    try {
        const response = await ballot.methods.registerDonor().send({ from: sender, gas: 200000 });
        this.setState({
          isDonor: true,
          isProcessing: false
        })
    } catch (e) {
        console.error(e); 
        this.setState({
          isProcessing: false
        });
    }
  }
    
  addRecipient = async () => {
    this.setState({
      isProcessing: true
    })
    const { contracts: { ballot }, sender } = this.state;
      try {
          const response = await ballot.methods.registerRecipient().send({ from: sender, gas: 200000 });
          this.setState({
            isRecipient: true,
            isProcessing: false
          })
      } catch (e) {
          console.error(e);
          this.setState({
            isProcessing: false
          })
      }
  }

  claimVote = async (voteId) => {
    this.setState({
      isProcessing: true
    })
        
    const { contracts: { ballot }, sender } = this.state
    
    try {
        const response = await ballot.methods.lockVote(voteId).send({ from: sender, gas: 200000 });
        this.setState({
          isProcessing: false
        })
    } catch (e) {
        console.error(e);
        this.setState({
          isProcessing: false
        })
    }
  }

  addVote = async (vote) => {

    this.setState({
      isProcessing: true
    })
    
    this.setState({
      isProcessing: true
    })

    const { contracts: { ballot }, sender } = this.state
    const data = JSON.stringify(vote)
    
    try {
        const response = await ballot.methods.createVote(data).send({ from: sender, gas: 300000});
        this.setState({
          isProcessing: false
        })
    } catch (e) {
        console.error(e);
        this.setState({
          isProcessing: false
        })
    }
  }

  confirmVote = async (vote) => {

    this.setState({
      isProcessing: true
    })
        
    const { contracts: { ballot }, sender, isDonor, isRecipient } = this.props.state
    
    try {
        if (isRecipient) {
            const response = await ballot.methods.confirmVoteRecipient(vote.id).send({ from: sender, gas: 200000 });
            this.setState({
              isProcessing: false
            })
        } else if (isDonor) {
            const response = await ballot.methods.confirmVoteDonor(vote.id).send({ from: sender, gas: 200000 });
            this.setState({
              isProcessing: false
            })
        }
    } catch (e) {
        console.error(e);
        this.setState({
          isProcessing: false
        })
    }
  }

  rejectVote = async (vote) => {
        
    const { contracts: { ballot }, sender, isDonor, isRecipient } = this.props.state
    
    try {
        if (isRecipient) {
            const response = await ballot.methods.rejectVoteRecipient(vote.id).send({ from: sender, gas: 200000 });
            this.setState({
              isProcessing: false
            })
        } else if (isDonor) {
            const response = await ballot.methods.rejectVoteDonor(vote.id).send({ from: sender, gas: 200000 });
            this.setState({
              isProcessing: false
            })
        }
    } catch (e) {
        console.error(e);
        this.setState({
          isProcessing: false
        })
    }
  }
  
  render() {
    if (!this.state.web3) {
      return <p>Loading... Please check the tutorial on the <a href="https://votetandem.org">votetandem.org</a> homepage and make sure to install the <a href="https://metamask.io">MetaMask browser</a> extension. <br/> Also make sure to switch MetaMask to the Ropsten network. For help: <a href="mailto:hello@votetandem.org">hello@votetandem.org</a></p>;
    }

    const { votes } = this.state

    // replace the ids in the actions with the product string
    const actions = this.state.actions.map((action) => {
      let vote = votes.find(offer => offer.id === action.id)
      return { ...action, vote }
    })

    return (
      <Router>
        <div id="app">
          <Route 
            exact path="/" 
            render={
              props => {
                return this.state.isDonor && !this.state.userVote.status ? (<Redirect to="/give" {...props} contractState={this.state} />)
                       : this.state.isDonor && this.state.userVote.status === "1" ? (<Redirect to="/pending" {...props} contractState={this.state} />)
                       : this.state.isDonor && this.state.userVote.status === "2" && !this.state.voteTimeOver ? (<Redirect to="/guide" {...props} contractState={this.state} />)
                       : this.state.isDonor && this.state.userVote.status === "2" && this.state.voteTimeOver ? (<Redirect to="/confirm" {...props} contractState={this.state} />)
                       : this.state.isDonor && this.state.userVote.status === "3" ? (<Redirect to="/confirm" {...props} contractState={this.state} />)
                       : this.state.isRecipient && !this.state.userVote.status ? (<Redirect to="/select" {...props} contractState={this.state} />)
                       : this.state.isRecipient && this.state.userVote.status === "1" ? (<Redirect to="/guide" {...props} contractState={this.state} />)
                       : this.state.isRecipient && this.state.userVote.status === "2" && !this.state.voteTimeOver ? (<Redirect to="/guide" {...props} contractState={this.state} />)
                       : this.state.isRecipient && this.state.userVote.status === "2" && this.state.voteTimeOver ? (<Redirect to="/confirm" {...props} contractState={this.state} />)
                       : this.state.isRecipient && this.state.userVote.status === "3" ? (<Redirect to="/confirm" {...props} contractState={this.state} />)
                       : (<HomeView {...props} contractState={this.state} registerAsDonor={this.addDonor} registerAsRecipient={this.addRecipient} />)
                      }
                    }
          />
          <Route 
            exact path="/give" 
            render={
              props => {
                return this.state.isDonor && !this.state.userVote.status ? (<GiveView {...props} contractState={this.state} addVote={this.addVote } />)
                       : this.state.isDonor && this.state.userVote.status === "1" ? (<Redirect to="/pending" {...props} contractState={this.state} />)
                       : this.state.isDonor && this.state.userVote.status === "2" && !this.state.voteTimeOver ? (<Redirect to="/guide" {...props} contractState={this.state} />)
                       : this.state.isDonor && this.state.userVote.status === "2" && this.state.voteTimeOver ? (<Redirect to="/confirm" {...props} contractState={this.state} />)
                       : this.state.isDonor && this.state.userVote.status === "3" ? (<Redirect to="/confirm" {...props} contractState={this.state} />)
                       : this.state.isRecipient && !this.state.userVote.status ? (<Redirect to="/select" {...props} contractState={this.state} />)
                       : this.state.isRecipient && this.state.userVote.status === "1" ? (<Redirect to="/guide" {...props} contractState={this.state} />)
                       : this.state.isRecipient && this.state.userVote.status === "2" && !this.state.voteTimeOver ? (<Redirect to="/guide" {...props} contractState={this.state} />)
                       : this.state.isRecipient && this.state.userVote.status === "2" && this.state.voteTimeOver ? (<Redirect to="/confirm" {...props} contractState={this.state} />)
                       : this.state.isRecipient && this.state.userVote.status === "3" ? (<Redirect to="/confirm" {...props} contractState={this.state} />)
                       : (<Redirect to="/" {...props} contractState={this.state} />)       
                      }
                    }
          />
          <Route 
            exact path="/select" 
            render={
              props => {
                return this.state.isRecipient && !this.state.userVote.status ? (<SelectView {...props} contractState={this.state} claimVote={this.claimVote} />)
                      : this.state.isDonor && !this.state.userVote.status ? (<Redirect to="/give" {...props} contractState={this.state} />)
                      : this.state.isDonor && this.state.userVote.status === "1" ? (<Redirect to="/pending" {...props} contractState={this.state} />)
                      : this.state.isDonor && this.state.userVote.status === "2" && !this.state.voteTimeOver ? (<Redirect to="/guide" {...props} contractState={this.state} />)
                      : this.state.isDonor && this.state.userVote.status === "2" && this.state.voteTimeOver ? (<Redirect to="/confirm" {...props} contractState={this.state} />)
                      : this.state.isDonor && this.state.userVote.status === "3" ? (<Redirect to="/confirm" {...props} contractState={this.state} />)
                      : this.state.isRecipient && this.state.userVote.status === "1" ? (<Redirect to="/guide" {...props} contractState={this.state} />)
                      : this.state.isRecipient && this.state.userVote.status === "2" && !this.state.voteTimeOver ? (<Redirect to="/guide" {...props} contractState={this.state} />)
                      : this.state.isRecipient && this.state.userVote.status === "2" && this.state.voteTimeOver ? (<Redirect to="/confirm" {...props} contractState={this.state} />)
                      : this.state.isRecipient && this.state.userVote.status === "3" ? (<Redirect to="/confirm" {...props} contractState={this.state} />)
                      : (<Redirect to="/" {...props} contractState={this.state} />)    
              }
            }
          />
          <Route 
            exact path="/pending" 
            render={
              props => {
                return this.state.isDonor && this.state.userVote.status === "1" ? (<PendingView {...props} contractState={this.state} />)
                      : this.state.isDonor && !this.state.userVote.status ? (<Redirect to="/give" {...props} contractState={this.state} />)
                      : this.state.isDonor && this.state.userVote.status === "2" && !this.state.voteTimeOver ? (<Redirect to="/guide" {...props} contractState={this.state} />)
                      : this.state.isDonor && this.state.userVote.status === "2" && this.state.voteTimeOver ? (<Redirect to="/confirm" {...props} contractState={this.state} />)
                      : this.state.isDonor && this.state.userVote.status === "3" ? (<Redirect to="/confirm" {...props} contractState={this.state} />)
                      : this.state.isRecipient && !this.state.userVote.status ? (<Redirect to="/select" {...props} contractState={this.state} />)
                      : this.state.isRecipient && this.state.userVote.status === "1" ? (<Redirect to="/guide" {...props} contractState={this.state} />)
                      : this.state.isRecipient && this.state.userVote.status === "2" && !this.state.voteTimeOver ? (<Redirect to="/guide" {...props} contractState={this.state} />)
                      : this.state.isRecipient && this.state.userVote.status === "2" && this.state.voteTimeOver ? (<Redirect to="/confirm" {...props} contractState={this.state} />)
                      : this.state.isRecipient && this.state.userVote.status === "3" ? (<Redirect to="/confirm" {...props} contractState={this.state} />)
                      : (<Redirect to="/" {...props} contractState={this.state} />)  
              }
            }
          />
          <Route 
            exact path="/guide" 
            render={
              props => {
                return (this.state.isDonor || this.state.isRecipient) && this.state.userVote.status === "2" && !this.state.voteTimeOver ? (<GuideView {...props} contractState={this.state} />)
                        : this.state.isRecipient && this.state.userVote.status === "1" ? (<GuideView {...props} contractState={this.state} />)
                        : this.state.isDonor && !this.state.userVote.status ? (<Redirect to="/give" {...props} contractState={this.state} />)
                        : this.state.isDonor && this.state.userVote.status === "1" ? (<Redirect to="/pending" {...props} contractState={this.state} />)
                        : this.state.isDonor && this.state.userVote.status === "2" && this.state.voteTimeOver ? (<Redirect to="/confirm" {...props} contractState={this.state} />)
                        : this.state.isDonor && this.state.userVote.status === "3" ? (<Redirect to="/confirm" {...props} contractState={this.state} />)
                        : this.state.isRecipient && !this.state.userVote.status ? (<Redirect to="/select" {...props} contractState={this.state} />)
                        : this.state.isRecipient && this.state.userVote.status === "2" && this.state.voteTimeOver ? (<Redirect to="/confirm" {...props} contractState={this.state} />)
                        : this.state.isRecipient && this.state.userVote.status === "3" ? (<Redirect to="/confirm" {...props} contractState={this.state} />)
                        : (<Redirect to="/" {...props} contractState={this.state} />)  
              }
            }
          />
          <Route 
            exact path="/confirm" 
            render={
              props => {
                return (this.state.isDonor || this.state.isRecipient) && this.state.userVote.status === "3" ? (<ConfirmView {...props} contractState={this.state} confirmVote={this.confirmVote} rejectVote={this.rejectVote} />)
                       : (this.state.isDonor || this.state.isRecipient) && this.state.userVote.status === "2" && this.state.voteTimeOver ? (<ConfirmView {...props} contractState={this.state} />)
                       : this.state.isDonor && !this.state.userVote.status ? (<Redirect to="/give" {...props} contractState={this.state} />)
                       : this.state.isDonor && this.state.userVote.status === "1" ? (<Redirect to="/pending" {...props} contractState={this.state} />)
                       : this.state.isDonor && this.state.userVote.status === "2" && !this.state.voteTimeOver ? (<Redirect to="/guide" {...props} contractState={this.state} />)
                       : this.state.isRecipient && !this.state.userVote.status ? (<Redirect to="/select" {...props} contractState={this.state} />)
                       : this.state.isRecipient && this.state.userVote.status === "1" ? (<Redirect to="/guide" {...props} contractState={this.state} />)
                       : this.state.isRecipient && this.state.userVote.status === "2" && !this.state.voteTimeOver ? (<Redirect to="/guide" {...props} contractState={this.state} />)
                       : (<Redirect to="/" {...props} contractState={this.state} />)
              }
            }
          />
          <Loader isProcessing={this.state.isProcessing}></Loader>
        </div>
      </Router>
    );
  }
}

export default App;