import React, { Component } from 'react';
import strings from "../utils/localization";
import Header from "../components/Header";
import Content from "../components/Content";
import Footer from "../components/Footer";
import Clock from "../img/clock.png";
import ClockRetina from "../img/clock@2x.png";
import ExclamationMark from "../img/attention.svg";

class PendingView extends Component {
    data = JSON.parse(this.props.contractState.userVote.data);
    location = this.data.location;
    time = new Date(this.data.time).toLocaleDateString() + " – " + new Date(this.data.time).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12: false});
    
    render() { 

        return (
            <div>
                <Header pageTitle={strings.headerTitlePending} pageDesc={strings.headerDescPending} />
                <Content>
                    <div className="content-right">
                        <div className="content-single">
                            <img src={Clock} className="content__image" alt="" srcSet={`${Clock} 1x, ${ClockRetina} 2x`}/>
                            <div className="content-description">
                                <div className="content-description__item" style={{flexGrow: "1"}}>
                                    <h2 className="content-description__title">{strings.textPendingTitle}</h2>
                                    <div className="content-description__columns--two">
                                        <div className="column">
                                            <p>{strings.location}:</p>
                                            <p>{this.location}</p>
                                        </div>
                                        <div className="column">
                                            <p>{strings.dateAndTime}:</p>
                                            <p>{this.time}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-description--icon">
                                    <div className="content__square-icon">
                                        <img src={ExclamationMark} alt="Icon Exclamation Mark"/>
                                    </div>
                                    <div className="content-description__item">
                                        <p>{strings.textPending}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Content>
                <Footer></Footer>
            </div>
         );
    }
}
 
export default PendingView;