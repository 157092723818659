import React, { Component } from 'react';
import strings from "../utils/localization";
import moment from "moment";
import AddToCalendar from "react-add-to-calendar";
import Header from "../components/Header";
import Content from "../components/Content";
import Footer from "../components/Footer";

class GuideView extends Component {
    location = JSON.parse(this.props.contractState.userVote.data).location;
    time = JSON.parse(this.props.contractState.userVote.data).time;

    state = {
        event: {
            title: strings.calenderEventTitle,
            description: strings.calenderEventDescription + " ---- " + strings.textTitleGuide + ":  – " + strings.textBulletGuide1 + "  – " + strings.textBulletGuide2 + "  – " + strings.textBulletGuide3 + "  – " + strings.textBulletGuide4 + " " + strings.textTitleTalk + ":  – " + strings.textBulletTalk1 + "  – " + strings.textBulletTalk2 + "  – " + strings.textBulletTalk3 + "  – " + strings.textBulletTalk4 + "  – " + strings.textBulletTalk5,
            location: this.location,
            startTime: moment(this.time).format(),
            endTime: moment(this.time).add(1, 'h').format()
        }
    }
    
    render() {
        return (
            <div>
                <Header pageTitle={strings.headerTitleGuide} pageDesc={strings.headerDescGuide} />
                <Content>
                    <div className="content-sidebar">
                        <div className="content-sidebar__item">
                            <p>{new Date(this.time).toLocaleDateString() + " – " + new Date(this.time).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12: false})}</p>
                            <p>{this.location}</p>
                            <AddToCalendar event={this.state.event} buttonLabel={strings.actionAddToCalendar} />
                        </div>
                        <div className="content-sidebar__item content-sidebar__item--helper-ballot">
                            <p>{strings.helperReminderBallot}</p>
                        </div>
                    </div>
                    <div className="content-right">
                        <div className="content__box">
                            <h2>{strings.textTitleGuide}</h2>
                            <ul>
                                <li>{strings.textBulletGuide1}</li>
                                <li>{strings.textBulletGuide2}</li>
                                <li>{strings.textBulletGuide3}</li>
                                <li>{strings.textBulletGuide4}</li>
                            </ul>
                        </div>
                        <div className="content__box">
                            <h2>{strings.textTitleTalk}</h2>
                            <ul>
                                <li>{strings.textBulletTalk1}</li>
                                <li>{strings.textBulletTalk2}</li>
                                <li>{strings.textBulletTalk3}</li>
                                <li>{strings.textBulletTalk4}</li>
                                <li>{strings.textBulletTalk5}</li>
                            </ul>
                        </div>
                    </div>
                </Content>
                <Footer></Footer>
            </div>
         );
    }
}
 
export default GuideView;