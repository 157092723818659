import React, { Component } from 'react';
import strings from "../utils/localization";

class VoteListItem extends Component {

    time = new Date(this.props.time).toLocaleDateString() + " – " + new Date(this.props.time).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12: false});
    location = this.props.location;

    render() {
        return (
            <div className="vote">
                <div className="vote-prop">
                    <span className="vote-prop__time">{this.time}</span>
                    <p className="vote-prop__location">{this.location}</p>
                </div>
                <button className="btn btn--small vote__reserve-button" onClick={this.props.reserveVote}>{strings.actionReserveVote}</button>
            </div>
        )
    }
}

export default VoteListItem;