import React, { Component } from 'react';
import "../App.scss";

class Content extends Component {

    render() { 
        return ( 
            <main className="content">
                {this.props.children}
            </main> 
        );
    }
}
 
export default Content;