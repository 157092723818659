import React, { Component } from 'react';
import "../App.scss";
import Logo from "../img/votetandem-logo.svg";

class Header extends Component {
    
    render() { 
        const pageTitle = this.props.pageTitle;
        const pageDesc = this.props.pageDesc;

        return ( 
            <header className="header">
                <div className="header-title">
                    <span className="header-title__brand">votetandem.org</span>
                    <h1 className="header-title__text">{pageTitle}</h1>
                </div>
                <div className="header-desc">
                    <img className="header-desc__icon" src={Logo} alt="votetandem.org logo"/>
                    <p className="header-desc__text">{pageDesc}</p>
                </div>
            </header> 
        );
    }
}
 
export default Header;