import React, { Component } from "react";
import strings from "../utils/localization";
import Video from "../img/Hourglass.mp4";
import VideoRetina from "../img/Hourglass@2x.mp4";

class Loader extends Component {
    render() { 

        if(!this.props.isProcessing) {
            return null
        }
        return (
            <div className="loader">
                <div className="loader-box">
                    <video className="loader-box__image" alt="" autoPlay muted loop playsInline width="225px">
                        <source type="video/mp4" media="(min-device-pixel-ratio: 2), (-webkit-min-device-pixel-ratio:2), (min--moz-device-pixel-ratio:2), (-o-min-device-pixel-ratio:2)" src={VideoRetina} />
                        <source type="video/mp4" media="(min-device-pixel-ratio: 1), (-webkit-min-device-pixel-ratio:1), (min--moz-device-pixel-ratio:1), (-o-min-device-pixel-ratio:2)" src={Video}/>
                    </video>
                    <p className="loader-box__text">
                        {strings.helperTransactionPending}
                    </p>
                </div>
            </div>
        );
    }
}
 
export default Loader;